// src/firebaseConfig.js
import firebase from "firebase/app";
import "firebase/firebase-firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAtAcVcy8p9VhuUhBT62H8ee4457cQ4u4w",
  authDomain: "acceso-energia-y-felicidad.firebaseapp.com",
  projectId: "acceso-energia-y-felicidad",
  storageBucket: "acceso-energia-y-felicidad.appspot.com",
  messagingSenderId: "380176186885",
  appId: "1:380176186885:web:9a9b76725935980717266c",
  measurementId: "G-BQSDEC28KH",
};

// Initialize Firebase
export default firebase.initializeApp(firebaseConfig);
