<template>
  <b-navbar>
    <template #brand>
      <b-navbar-item tag="div">
        <img
          src="../assets/logo-congreso.png"
          alt="Primer congreso Energía&Felicidad"
        />
      </b-navbar-item>
    </template>
    <template #start>
      <b-navbar-item tag="div">
        <h1 class="title" style="line-height: 110px">Control de ocupación</h1>
      </b-navbar-item>
    </template>
    <template #end>
      <b-navbar-item tag="div">
        <img
          src="../assets/metech_industrial.png"
          alt="Metech metamorfosis digital"
        />
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
export default {
  name: "TitleBar",
};
</script>
<style>
.navbar-item img {
  display: block;
  max-height: 75px !important;
  width: auto;
  padding: 5px 30px;
}

.image img {
  margin: 1rem auto 0;
}
</style>
