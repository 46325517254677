/* eslint-disable */
<template>
  <div>
    <TitleBar />

    <section class=".container">
      <div class="columns">
        <div class="column is-one-third">
          <Selector />
        </div>

        <div class="column is-two-thirds">
          <b-tabs v-model="activeTab" type="is-toggle" expanded>
            <OcupationTable workshop="energia" />
            <OcupationTable workshop="escucha" />
            <OcupationTable workshop="humor" />
            <OcupationTable workshop="meditacion" />
          </b-tabs>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import OcupationTable from "./components/table.vue";
import Selector from "./components/selector.vue";
import TitleBar from "./components/nav.vue";

export default {
  components: { TitleBar, Selector, OcupationTable },
  name: "AccessControl",
  data() {
    return {
      activeTab: 0,
    };
  },
};
</script>
