<template>
  <b-tab-item>
    <template #header>
      <span>
        {{ header.tab }} &ensp;
        <b-tag
          rounded
          :type="inscritos.length <= 25 ? 'is-success' : 'is-danger'"
          >{{ inscritos.length }}</b-tag
        >
      </span>
    </template>
    <div class="panel">
      <div class="panel-heading">
        <h2 class="title is-4">
          {{ header.title }}
          <h3 class="subtitle">{{ header.time }}</h3>
        </h2>
      </div>
      <div class="panel-block">
        <table class="table table is-fullwidth is-striped">
          <thead>
            <tr>
              <th>Inscripcion</th>
              <th>Nombre</th>
              <th>DNI.</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(inscrito, index ) in inscritos"
              :id="workshop + '-' + inscrito.trueId"
              :key="inscrito.trueId"
              :class="[inscrito.mark ? 'has-background-success-light' : '', index > 24 ? 'has-background-danger-light' : '']"
            >
              <td class="has-text-right">{{ inscrito.hora }}</td>
              <td class="has-text-left">{{ inscrito.nombre }}</td>
              <td class="has-text-right">{{ inscrito.dni }}</td>
              <td>
                <button
                  class="button is-outlined is-danger"
                  @click="deleteInscripcion(inscrito.trueId)"
                >
                  Borrar
                </button>
              </td>
              <td>
                <button
                  class="button is-outlined is-success"
                  :disabled="index > 24"
                  @click="
                    markAttendee(
                      $event,
                      inscrito.trueId,
                      inscrito.mark
                    )
                  "
                >
                  <span v-show="!inscrito.mark">Marcar</span>
                  <span v-show="inscrito.mark">No Marcar</span>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </b-tab-item>
</template>

<script>
import firebase from "../config/firebaseConfig";

const db = firebase.firestore();
export default {
  name: "OcupationTable",
  props: {
    workshop: String,
  },
  data() {
    return {
      inscritos: [],
      headers: {
        humor: {
          tab: "El humor es energía",
          title:
            "EL HUMOR ES ENERGÍA, cómo activar tu sentido del humor con Daniel Delacamara",
          time: "10.00-11.00",
        },
        escucha: {
          tab: "Escuchar para ser",
          title:
            "ESCUCHAR PARA SER: La relación interpersonal en clave contemplativa con Pablo D’Ors",
          time: "12.00-13.00h",
        },
        meditacion: {
          tab: "Meditación",
          title: "MEDITACIÓN con Alejandra Vallejo Nágera",
          time: "15.30-16.30h",
        },
        energia: {
          tab: "Salud física y energía",
          title: "SALUD FÍSICA Y ENERGÍA con Juan Ignacio Martínez Salamanca",
          time: "17.15-18.15h",
        },
      },
    };
  },
  computed: {
    header() {
      switch (this.workshop) {
        case "energia":
          return this.headers.energia;
        case "escucha":
          return this.headers.escucha;
        case "humor":
          return this.headers.humor;
        default:
          return this.headers.meditacion;
      }
    },
  },
  methods: {
    addUnique(arr, dni) {
      const found = arr.some((el) => el.dni === dni);
      if (!found) return true;
    },
    readRegisters() {
      db.collection(this.workshop)
        .orderBy("inscripcion", "asc")
        .onSnapshot(
          (snap) => {
            this.inscritos = [];
            snap.forEach((doc) => {
              if (this.addUnique(this.inscritos, doc.data().dni)) {
                this.inscritos.push({
                  trueId: doc.id,
                  hora: doc.data().inscripcion,
                  id: doc.data().id,
                  nombre: doc.data().nombre,
                  dni: doc.data().dni,
                  mark: doc.data().mark,
                });
              }
            });
          },
          function (error) {
            console.error("Error getting snapshot document: ", error);
          }
        );
    },

    deleteInscripcion(id) {
      let elem = document.querySelector(`#${this.workshop}-${id}`);
      console.log(elem);
      elem.classList.add("animate__animated", "animate__bounceOutright");
      setTimeout(() => {
        db.collection(this.workshop)
          .doc(id)
          .delete()
          .then(() => {
            console.log("Document successfully deleted!");
            this.readRegisters();
          })
          .catch((error) => {
            console.error("Error removing document: ", error);
          });
      }, 350);
    },
    markAttendee(e, id, mark) {
      let elem = document.querySelector(`#${this.workshop}-${id}`);
      console.log(elem);
      if (!mark) {
        elem.classList.add("has-background-success-light");
      } else {
        elem.classList.remove("has-background-success-light");
      }
      db.collection(this.workshop)
        .doc(id)
        .update({ mark: !mark })
        .then(() => {
          console.log("user updated!");
        });
    },
  },
  created() {
    this.readRegisters();
  },
};
</script>

<style></style>
