<template>
  <section>
    <div class="panel">
      <div class="panel-heading">
        Asistente:
        <br />
        <b v-if="selected">
          {{ selected.name }}
          {{ selected.dni }}
        </b>
      </div>
      <div class="panel-block">
        <b-field>
          <b-autocomplete
            v-model="name"
            placeholder="e.g. Anne"
            :keep-first="keepFirst"
            :open-on-focus="openOnFocus"
            :data="filteredDataObj"
            field="name"
            @select="(option) => (selected = option)"
            :clearable="clearable"
          ></b-autocomplete>
        </b-field>
      </div>
      <div class="panel-block"></div>
      <div class="panel-block">
        <b-checkbox-button
          v-model="checkboxGroup"
          native-value="energia"
          type="is-success"
        >
          <b-icon icon="check"></b-icon>
          <span>Salud física y energía</span>
        </b-checkbox-button>
      </div>
      <div class="panel-block">
        <b-checkbox-button
          v-model="checkboxGroup"
          native-value="escucha"
          type="is-success"
        >
          <b-icon icon="check"></b-icon>
          <span>Escuchar para ser</span>
        </b-checkbox-button>
      </div>
      <div class="panel-block">
        <b-checkbox-button
          v-model="checkboxGroup"
          native-value="humor"
          type="is-success"
        >
          <b-icon icon="check"></b-icon>
          <span>El humor es energía</span>
        </b-checkbox-button>
      </div>
      <div class="panel-block">
        <b-checkbox-button
          v-model="checkboxGroup"
          native-value="meditacion"
          type="is-success"
        >
          <b-icon icon="check"></b-icon>
          <span>Meditación</span>
        </b-checkbox-button>
      </div>
    </div>
    <div class="buttons">
      <b-button type="is-success" @click="sendRegistry" expanded
        >Inscribir</b-button
      >
    </div>
  </section>
</template>

<script>
import firebase from "../config/firebaseConfig";
import { toast } from "bulma-toast";
const db = firebase.firestore();

export default {
  name: "Selector",
  data() {
    return {
      data: [],
      keepFirst: false,
      openOnFocus: true,
      name: "",
      selected: null,
      clearable: false,
      checkboxGroup: [],
      workshops: ["energia", "humor", "escucha", "meditacion"],
    };
  },
  computed: {
    filteredDataObj() {
      return this.data.filter((option) => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.name.toLowerCase()) >= 0
        );
      });
    },
  },
  methods: {
    sendRegistry() {
      let user = this.selected;
      let collection = this.checkboxGroup;

      let hoy = new Date();
      let hour = hoy.getHours();
      let minutes = hoy.getMinutes();
      let seconds = hoy.getSeconds();
      if (hour < 10) {
        hour = "0" + hour;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      let hora = `${hour}:${minutes}:${seconds}`;

      collection.forEach((checkbox) => {
        db.collection(checkbox)
          .add({
            id: user.id,
            nombre: user.name,
            dni: user.dni,
            inscripcion: hora,
            mark: false,
          })
          .then(() => {
            toast({
              message: `La inscripción en el taller ${checkbox} ha sido realizada`,
              duration: 2000,
              position: "top-right",
              closeOnClick: true,
              opacity: 1,
              single: false,
              type: "is-success",
            });
          })
          .catch((error) => {
            console.error("Error writing document: ", error);
          });
      });
      this.selected = null;
      this.name = "";
      this.checkboxGroup = [];
    },
  },
  async created() {
    // GET request using fetch with async/await
    const response = await fetch(
      "https://inscripciones.telva.com/api/v1/i-congreso/registrations-noauth"
    );
    this.data = await response.json();
  },
};
</script>

<style>
.card-image {
  border-bottom: 1px solid #363636;
}

.field {
  width: 100%;
}
.b-checkbox.checkbox {
  justify-content: start;
}
.tabs.is-toggle li.is-active a {
  background-color: #363636 !important;
  border-color: #363636 !important;
}
</style>
